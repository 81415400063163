import React, { useState, useEffect, useRef } from "react";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import moment from "moment/min/moment-with-locales";
import { isNil } from "lodash";

import cities from "../lists/cities";
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import PhotoSizeSelectSmallIcon from "@mui/icons-material/PhotoSizeSelectSmall";
import { makeStyles } from "@mui/styles";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import { useTranslation } from "react-i18next";
import { Modal, Grid, Typography } from "@mui/material";
import Button from "components/CustomButtons/Button.js";
import CancelIcon from "@mui/icons-material/Cancel";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { colors } from "../components/Theme/WebTheme";
import { carTypeColumns, optionsRequired } from "common/sharedFunctions";
import { Autocomplete } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { CalendarMonth, LocationOn, Schedule } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  submit3: {
    width: "100%",
    borderRadius: 3,
    marginTop: 2,
    padding: 4,
  },
  paper: {
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CarTypes() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const inputRef = useRef(null);
  const settings = useSelector((state) => state.settingsdata.settings);
  const carlistdata = useSelector((state) => state.carlistdata);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const { editCarType, updateUserCar, editCar, fetchUsersOnce } = api;
  const [data, setData] = useState([]);
  const cartypes = useSelector((state) => state.cartypes);
  const dispatch = useDispatch();
  const rootRef = useRef(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [modalType, setModalType] = useState();

  const [toastMessage, setToastMessage] = useState(null);
  const [openToast, setOpenToast] = useState(false);

  const [modalTypePriceByCity, setModalTypePriceByCity] = useState(false);
  const [modalTypePriceByTime, setModalTypePriceByTime] = useState(false);
  const [modalTypePriceByDate, setModalTypePriceByDate] = useState(false);

  const onClick = (rowData) => {
    setImageData(rowData.image);
    setProfileModal(true);
    setUserData(rowData);
  };

  const handleCloseToast = () => {
    setToastMessage(null);
    setOpenToast(false);
  }

  const columns = carTypeColumns(t, isRTL, onClick);
  const subcolumns = [
    {
      title: t("description"),
      field: "description",
      render: (rowData) => <span>{rowData.description}</span>,
    },
    { title: t("amount"), field: "amount", type: "numeric" },
  ];

  const columnPriceCity = [
    {
      title: "Cidade",
      field: "city",
      render: (rowData) => <span>{rowData.city}</span>,
      editComponent: (props) => (
        <Autocomplete
          id="combo-box-demo"
          options={cities}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Cidades" variant="outlined" />
          )}
          onChange={(event, newValue) => {
            props.onChange(newValue.id);
          }}
        />
      ),
    },
    {
      title: t("base_fare"),
      field: "base_fare",
      type: "numeric",
      cellStyle: { textAlign: "center" },
      initialEditValue: 0,
    },
    {
      title: t("rate_per_unit_distance"),
      field: "rate_per_unit_distance",
      type: "numeric",
      cellStyle: { textAlign: "center" },
      initialEditValue: 0,
    },
    {
      title: t("rate_per_hour"),
      field: "rate_per_hour",
      type: "numeric",
      cellStyle: { textAlign: "center" },
      initialEditValue: 0,
    },
    {
      title: t("min_fare"),
      field: "min_fare",
      type: "numeric",
      cellStyle: { textAlign: "center" },
      initialEditValue: 0,
    },
    {
      title: t("convenience_fee"),
      field: "convenience_fees",
      type: "numeric",
      cellStyle: { textAlign: "center" },
      initialEditValue: 0,
    },
    {
      title: t("convenience_fee_type"),
      field: "convenience_fee_type",
      lookup: { flat: t("flat"), percentage: t("percentage") },
      cellStyle: { textAlign: "center" },
    },
  ];

  const columnPriceByTime = [
    {
      title: t('initialTimeColumn'),
      field: 'initialTime',
      type: 'time',
      cellStyle: { textAlign: 'center' },
      render: rowData => rowData?.initialTime ? dayjs(rowData.initialTime, 'HH:mm:ss').format('HH:mm') : null,
      editComponent: props => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            inputRef={inputRef}
            renderInput={(params) => <TextField {...params} />}
            label={t('initialTimeColumn')}
            value={props.value ? dayjs(props.value, 'HH:mm:ss') : null}
            onChange={(newValue) => {
              if (newValue) {
                const newInitialTime = newValue.format('HH:mm:ss');
                props.onChange(newInitialTime);
              }
            }}
            format="HH:mm"
            ampm={false}
            autoFocus={false}
          />
        </LocalizationProvider>
      ),
    },
    {
      title: t('finalTimeColumn'),
      field: 'finalTime',
      type: 'time',
      cellStyle: { textAlign: 'center' },
      render: rowData => rowData?.finalTime ? dayjs(rowData.finalTime, 'HH:mm:ss').format('HH:mm') : null,
      editComponent: props => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <TimePicker
            inputRef={inputRef}
            renderInput={(params) => <TextField {...params} />}
            label={t('finalTimeColumn')}
            value={props.value ? dayjs(props.value, 'HH:mm:ss') : null}
            onChange={(newValue) => {
              if (newValue) {
                const newFinalTIme = newValue.format('HH:mm:ss');
                props.onChange(newFinalTIme);
              }
            }}
            format="HH:mm"
            ampm={false}
            autoFocus={false}
          />
        </LocalizationProvider>
      ),
    },
    {
      title: t('AdditionalFeePercentColumn'),
      field: 'additionalFee',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      initialEditValue: 0,
      render: rowData => rowData?.additionalFee || 0,
      editComponent: props => (
        <TextField
          type="number"
          value={props.value || ''}
          onChange={e => {
            const newValue = e.target.value;
            if (newValue === '' || (Number(newValue) >= 1 && Number(newValue) <= 100)) {
              props.onChange(parseFloat(newValue));
            }
          }}
          inputProps={{ min: 1, max: 100, step: 0.01 }}
          style={{ width: '100%' }}
        />
      ),
    }
  ];

  const columnPriceByDate = [
    {
      title: t('dateColumn'), field: 'day', type: 'date', cellStyle: { textAlign: 'center' },
      render: rowData => rowData?.day ? moment(rowData?.day).format('DD/MM/YYYY') : null,
      editComponent: props => (
        <>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              inputRef={inputRef}
              renderInput={(params) => <TextField {...params} />}
              label={t('dateColumn')}
              value={props.value ? moment(props.value, "YYYY-MM-DD").toDate() : null}
              onChange={(newValue) => {
                if (newValue) {
                  const newDay = moment(newValue.toDate()).format('YYYY-MM-DD');
                  props.onChange(newDay);
                } else {
                  props.onChange(null);
                }
              }}
              format="DD/MM/YYYY"
              disableToolbar
              autoOk
            />
          </LocalizationProvider>
        </>
      ),
    },
    {
      title: t('AdditionalFeePercentColumn'),
      field: 'additionalFee',
      type: 'numeric',
      cellStyle: { textAlign: 'center' },
      initialEditValue: 0,
      render: rowData => rowData?.additionalFee || 0,
      editComponent: props => (
        <TextField
          type="number"
          value={props.value || ''}
          onChange={e => {
            const newValue = e.target.value;
            if (newValue === '' || (Number(newValue) >= 1 && Number(newValue) <= 100)) {
              props.onChange(parseFloat(newValue));
            }
          }}
          inputProps={{ min: 1, max: 100, step: 0.01 }}
          style={{ width: '100%' }}
        />
      ),
    }
  ];

  const subcolumns2 = [
    {
      title: t("minsDelayed"),
      field: "minsDelayed",
      render: (rowData) => <span>{rowData.minsDelayed}</span>,
    },
    { title: t("amount"), field: "amount", type: "numeric" },
  ];

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (cartypes.cars) {
      setData(cartypes.cars);
    } else {
      setData([]);
    }
  }, [cartypes.cars]);

  const [selectedImage, setSelectedImage] = useState(null);
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  };

  const [userData, setUserData] = useState();
  const [profileModal, setProfileModal] = useState(false);
  const [imageData, setImageData] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loading, setLoading] = useState(false);

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handleSetProfileModal = (e) => {
    e.preventDefault();
    if (selectedImage) {
      setLoading(true);
      let finalData = userData;
      finalData.image = selectedImage;
      dispatch(editCarType(finalData, "UpdateImage"));
      setProfileModal(false);
      setTimeout(() => {
        setSelectedImage(null);
        setLoading(false);
      }, 10000);
    } else {
      setCommonAlert({ open: true, msg: t("choose_image_first") });
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const validateFieldsDays = (newData) => {
    if (isNil(newData)) {
      setToastMessage('Por favor, preencha os dados corretamente!');
      setOpenToast(true);
      return false;
    } else if (isNil(newData?.day)) {
      setToastMessage('Por favor, preencha o campo de data inicial');
      setOpenToast(true);
      return false;
    } else if ((isNil(newData?.additionalFee))) {
      setToastMessage('Por favor, preencha o campo de taxa adicional');
      setOpenToast(true);
      return false;
    } else if (newData?.additionalFee < 1 || newData?.additionalFee > 100) {
      setToastMessage('O valor da taxa adicional deve ser entre 1 e 100 porcento');
      setOpenToast(true);
      return false;
    } else {
      setToastMessage(null);
      setOpenToast(false);
      return true;
    }
  }

  const validateTimes = (newData) => {
    const initialTime = dayjs(newData.initialTime, 'HH:mm:ss');
    const finalTime = dayjs(newData.finalTime, 'HH:mm:ss');
    if (initialTime.isAfter(finalTime)) {
      return false;
    } else {
      return true;
    }
  }

  const validateFieldsTimes = (newData) => {
    if (isNil(newData)) {
      setToastMessage('Por favor, preencha os dados corretamente!');
      setOpenToast(true);
      return false;
    } else if (isNil(newData?.initialTime)) {
      setToastMessage('Por favor, preencha o campo de horário inicial');
      setOpenToast(true);
      return false;
    } else if (isNil(newData?.finalTime)) {
      setToastMessage('Por favor, preencha o campo de horário final');
      setOpenToast(true);
      return false;
    } else if (!validateTimes(newData)) {
      setToastMessage('O horário inicial deve ser menor que o horário final');
      setOpenToast(true);
      return false;
    } else if ((isNil(newData?.additionalFee))) {
      setToastMessage('Por favor, preencha o campo de taxa adicional');
      setOpenToast(true);
      return false;
    } else if (newData?.additionalFee < 1 || newData?.additionalFee > 100) {
      setToastMessage('O valor da taxa adicional deve ser entre 1 e 100 porcento');
      setOpenToast(true);
      return false;
    } else {
      setToastMessage(null);
      setOpenToast(false);
      return true;
    }
  }

  return cartypes.loading ? (
    <CircularLoading />
  ) : (
    <div ref={rootRef}>
      <Snackbar open={openToast} autoHideDuration={3000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="warning">
          {toastMessage}
        </Alert>
      </Snackbar>
      <MaterialTable
        title={t("car_type")}
        columns={columns}
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: "4px 4px 6px #9E9E9E",
        }}
        data={data}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          exportButton: true,
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
          }),
          editable: {
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: 'Lucida Console", "Courier New", monospace',
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: colors.Header_Text_back,
            color: "#fff",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: 'Lucida Console", "Courier New", monospace',
          },
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve, reject) => {
                setTimeout(() => {
                  if (newData && newData.name) {
                    newData["createdAt"] = new Date().getTime();
                    dispatch(editCarType(newData, "Add"));
                    resolve();
                  } else {
                    alert(t("proper_input_name"));
                    reject();
                  }
                }, 600);
              })
              : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(t("demo_mode"));
                }, 600);
              }),
          onRowUpdate: (newData, oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  if (newData !== oldData) {
                    delete newData.tableData;
                    if (newData.name !== oldData.name) {
                      let users = staticusers
                        ? staticusers?.filter(
                          (user) =>
                            user.usertype === "driver" &&
                            user.carType === oldData.name
                        )
                        : [];
                      for (let i = 0; i < users.length; i++) {
                        dispatch(
                          updateUserCar(users[i].id, {
                            carType: newData.name,
                          })
                        );
                      }
                      let cars = carlistdata.cars.filter(
                        (car) => car.carType === oldData.name
                      );
                      for (let i = 0; i < cars.length; i++) {
                        dispatch(
                          editCar(
                            { ...cars[i], carType: newData.name },
                            "Update"
                          )
                        );
                      }
                    }
                    dispatch(editCarType(newData, "Update"));
                  }
                }, 600);
              })
              : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(t("demo_mode"));
                }, 600);
              }),
          onRowDelete: (oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  dispatch(editCarType(oldData, "Delete"));
                }, 600);
              })
              : new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  alert(t("demo_mode"));
                }, 600);
              }),
        }}
        actions={[
          (rowData) =>
            optionsRequired
              ? {
                icon: () => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <PhotoSizeSelectSmallIcon />
                    <Typography variant="subtitle2" style={{ padding: 5 }}>
                      {t("parcel_types")}
                    </Typography>
                  </div>
                ),
                onClick: (event, rowData) => {
                  setModalType("parcelTypes");
                  setRowIndex(rowData.tableData.id);
                  setOpen(true);
                },
              }
              : null,
          (rowData) =>
            optionsRequired
              ? {
                icon: () => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <FitnessCenterIcon />
                    <Typography variant="subtitle2" style={{ padding: 5 }}>
                      {t("options")}
                    </Typography>
                  </div>
                ),
                onClick: (event, rowData) => {
                  setModalType("options");
                  setRowIndex(rowData.tableData.id);
                  setOpen(true);
                },
              }
              : null,
          (rowData) => ({
            icon: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Tooltip title={t("cancelSlab")} placement="bottom">
                  <CancelScheduleSendIcon
                    style={{
                      fontSize: 25,
                      color: "black",
                    }}
                  />
                </Tooltip>
              </div>
            ),
            onClick: (event, rowData) => {
              setModalType("cancelSlab");
              setRowIndex(rowData.tableData.id);
              setOpen(true);
            },
          }),
          (rowData) => ({
            icon: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Tooltip title="Preços por cidade" placement="bottom">
                  <LocationOn
                    style={{
                      fontSize: 25,
                      color: "black",
                    }}
                  />
                </Tooltip>
              </div>
            ),
            onClick: (event, rowData) => {
              setModalTypePriceByCity(true);
              setRowIndex(rowData.tableData.id);
            },
          }),
          (rowData) => ({
            icon: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Tooltip title="Taxas por horário" placement="bottom">
                  <Schedule
                    style={{
                      fontSize: 25,
                      color: "black",
                    }}
                  />
                </Tooltip>
              </div>
            ),
            onClick: (event, rowData) => {
              setModalTypePriceByTime(true);
              setRowIndex(rowData.tableData.id);
            },
          }),
          (rowData) => ({
            icon: () => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Tooltip title="Taxas por dia" placement="bottom">
                  <CalendarMonth
                    style={{
                      fontSize: 25,
                      color: "black",
                    }}
                  />
                </Tooltip>
              </div>
            ),
            onClick: (event, rowData) => {
              setModalTypePriceByDate(true);
              setRowIndex(rowData.tableData.id);
            },
          }),
        ]}
      />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
              {t("car_image")}

              <input
                type="file"
                style={{ marginLeft: 10 }}
                name={t("image")}
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage && !loading ? (
              <Tooltip title={t("cancel")}>
                <CancelIcon
                  onClick={() => setSelectedImage(null)}
                  style={{
                    fontSize: 30,
                    backgroundColor: "red",
                    borderRadius: 50,
                    color: "white",
                  }}
                />
              </Tooltip>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage ? (
              <img
                alt="not fount"
                width={"200px"}
                height={"200px"}
                src={URL.createObjectURL(selectedImage)}
                style={{ marginTop: 15, marginBottom: 20 }}
              />
            ) : (
              <img
                alt="not fount"
                width={"200px"}
                height={"200px"}
                src={imageData}
                style={{ marginTop: 10 }}
              />
            )}
            <br />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleProfileModal}
                  variant="contained"
                  color="danger"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetProfileModal}
                  variant="contained"
                  color="secondaryButton"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={handleClose}
        open={open}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={
              modalType === "options"
                ? t("options")
                : modalType === "cancelSlab"
                  ? t("cancelSlab")
                  : t("parcel_types")
            }
            columns={modalType === "cancelSlab" ? subcolumns2 : subcolumns}
            data={
              data[rowIndex] && data[rowIndex][modalType]
                ? data[rowIndex][modalType]
                : []
            }
            options={{
              exportButton: true,
            }}
            localization={{
              body: {
                addTooltip: t("add"),
                deleteTooltip: t("delete"),
                editTooltip: t("edit"),
                emptyDataSourceMessage: t("blank_message"),
                editRow: {
                  deleteText: t("delete_message"),
                  cancelTooltip: t("cancel"),
                  saveTooltip: t("save"),
                },
              },
              toolbar: {
                searchPlaceholder: t("search"),
                exportTitle: t("export"),
              },
              header: {
                actions: t("actions"),
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
                firstTooltip: t("first_page_tooltip"),
                previousTooltip: t("previous_page_tooltip"),
                nextTooltip: t("next_page_tooltip"),
                lastTooltip: t("last_page_tooltip"),
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                settings.AllowCriticalEditsAdmin
                  ? new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      let tblData = data;
                      if (!tblData[rowIndex][modalType]) {
                        tblData[rowIndex][modalType] = [];
                      }
                      tblData[rowIndex][modalType].push(newData);
                      dispatch(editCarType(tblData[rowIndex]), "Update");
                    }, 600);
                  })
                  : new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      alert(t("demo_mode"));
                    }, 600);
                  }),
              onRowUpdate: (newData, oldData) =>
                settings.AllowCriticalEditsAdmin
                  ? new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      if (newData !== oldData) {
                        let tblData = data;
                        tblData[rowIndex][modalType][oldData.tableData.id] =
                          newData;
                        dispatch(editCarType(tblData[rowIndex]), "Update");
                      }
                    }, 600);
                  })
                  : new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      alert(t("demo_mode"));
                    }, 600);
                  }),
              onRowDelete: (oldData) =>
                settings.AllowCriticalEditsAdmin
                  ? new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      let tblData = data;
                      const index = tblData[rowIndex][modalType];
                      const neTtblData = index.filter(
                        (item) =>
                          item.amount !== oldData.amount &&
                          (modalType === "cancelSlab"
                            ? item.minsDelayed !== oldData.minsDelayed
                            : item.description !== oldData.description)
                      );
                      tblData[rowIndex][modalType] = neTtblData;
                      dispatch(editCarType(tblData[rowIndex]), "Update");
                    }, 600);
                  })
                  : new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      alert(t("demo_mode"));
                    }, 600);
                  }),
            }}
          />
        </div>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={() => setModalTypePriceByCity(false)}
        open={modalTypePriceByCity}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={"Preços por cidade"}
            columns={columnPriceCity}
            data={
              data[rowIndex] && data[rowIndex]["cities"]
                ? data[rowIndex]["cities"]
                : []
            }
            options={{
              exportButton: true,
            }}
            localization={{
              body: {
                addTooltip: t("add"),
                deleteTooltip: t("delete"),
                editTooltip: t("edit"),
                emptyDataSourceMessage: t("blank_message"),
                editRow: {
                  deleteText: t("delete_message"),
                  cancelTooltip: t("cancel"),
                  saveTooltip: t("save"),
                },
              },
              toolbar: {
                searchPlaceholder: t("search"),
                exportTitle: t("export"),
              },
              header: {
                actions: t("actions"),
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
                firstTooltip: t("first_page_tooltip"),
                previousTooltip: t("previous_page_tooltip"),
                nextTooltip: t("next_page_tooltip"),
                lastTooltip: t("last_page_tooltip"),
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(newData);
                    let tblData = data;
                    if (!tblData[rowIndex]["cities"]) {
                      tblData[rowIndex]["cities"] = [];
                    }
                    tblData[rowIndex]["cities"].push(newData);
                    dispatch(editCarType(tblData[rowIndex]), "Update");
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (newData !== oldData) {
                      let tblData = data;
                      tblData[rowIndex]["cities"][oldData.tableData.id] =
                        newData;
                      dispatch(editCarType(tblData[rowIndex]), "Update");
                    }
                  }, 600);
                }),

              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let tblData = data;
                    const index = tblData[rowIndex]["cities"];
                    const neTtblData = index.filter(
                      (item) => item.city !== oldData.city
                    );
                    tblData[rowIndex]["cities"] = neTtblData;
                    dispatch(editCarType(tblData[rowIndex]), "Update");
                  }, 600);
                }),
            }}
          />
        </div>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={() => setModalTypePriceByTime(false)}
        open={modalTypePriceByTime}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={"Taxas por horário"}
            columns={columnPriceByTime}
            data={
              data[rowIndex] && data[rowIndex]['times']
                ? data[rowIndex]['times']
                : []
            }

            options={{
              exportButton: true,
            }}
            localization={{
              body: {
                addTooltip: t("add"),
                deleteTooltip: t("delete"),
                editTooltip: t("edit"),
                emptyDataSourceMessage: t("blank_message"),
                editRow: {
                  deleteText: t("delete_message"),
                  cancelTooltip: t("cancel"),
                  saveTooltip: t("save"),
                },
              },
              toolbar: {
                searchPlaceholder: t("search"),
                exportTitle: t("export"),
              },
              header: {
                actions: t("actions"),
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
                firstTooltip: t("first_page_tooltip"),
                previousTooltip: t("previous_page_tooltip"),
                nextTooltip: t("next_page_tooltip"),
                lastTooltip: t("last_page_tooltip"),
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(newData);
                    if (validateFieldsTimes(newData)) {
                      let tblData = data;
                      if (!tblData[rowIndex]['times']) {
                        tblData[rowIndex]['times'] = [];
                      }
                      tblData[rowIndex]['times'].push(newData);
                      dispatch(editCarType(tblData[rowIndex]), "Update");
                    } else {
                      let tblData = data;
                      if (!tblData[rowIndex]['times']) {
                        tblData[rowIndex]['times'] = [];
                      }
                      tblData[rowIndex]['times'].push(newData);
                    }
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (newData !== oldData) {
                      if (validateFieldsTimes(newData)) {
                        let tblData = data;
                        tblData[rowIndex]['times'][oldData.tableData.id] =
                          newData;
                        dispatch(editCarType(tblData[rowIndex]), "Update");
                      } else {
                        let tblData = data;
                        tblData[rowIndex]['times'][oldData.tableData.id] =
                          newData;
                      }
                    }
                  }, 600);
                }),

              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let tblData = data;
                    const index = tblData[rowIndex]['times'];
                    const neTtblData = index.filter(
                      (item) =>
                        item !== oldData
                    );
                    tblData[rowIndex]['times'] = neTtblData;
                    dispatch(editCarType(tblData[rowIndex]), "Update");
                  }, 600);
                }),
            }}
          />
        </div>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        onClose={() => setModalTypePriceByDate(false)}
        open={modalTypePriceByDate}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <div className={classes.paper}>
          <MaterialTable
            title={"Taxas por dia"}
            columns={columnPriceByDate}
            data={
              data[rowIndex] && data[rowIndex]['days']
                ? data[rowIndex]['days']
                : []
            }

            options={{
              exportButton: true,
            }}
            localization={{
              body: {
                addTooltip: t("add"),
                deleteTooltip: t("delete"),
                editTooltip: t("edit"),
                emptyDataSourceMessage: t("blank_message"),
                editRow: {
                  deleteText: t("delete_message"),
                  cancelTooltip: t("cancel"),
                  saveTooltip: t("save"),
                },
              },
              toolbar: {
                searchPlaceholder: t("search"),
                exportTitle: t("export"),
              },
              header: {
                actions: t("actions"),
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
                firstTooltip: t("first_page_tooltip"),
                previousTooltip: t("previous_page_tooltip"),
                nextTooltip: t("next_page_tooltip"),
                lastTooltip: t("last_page_tooltip"),
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(newData);
                    if (validateFieldsDays(newData)) {
                      let tblData = data;
                      if (!tblData[rowIndex]['days']) {
                        tblData[rowIndex]['days'] = [];
                      }
                      tblData[rowIndex]['days'].push(newData);
                      dispatch(editCarType(tblData[rowIndex]), "Update");
                    } else {
                      let tblData = data;
                      if (!tblData[rowIndex]['days']) {
                        tblData[rowIndex]['days'] = [];
                      }
                      tblData[rowIndex]['days'].push(newData);
                    }
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    if (newData !== oldData) {
                      if (validateFieldsDays(newData)) {
                        let tblData = data;
                        tblData[rowIndex]['days'][oldData.tableData.id] =
                          newData;
                        dispatch(editCarType(tblData[rowIndex]), "Update");
                      } else {
                        let tblData = data;
                        tblData[rowIndex]['days'][oldData.tableData.id] =
                          newData;
                      }
                    }
                  }, 600);
                }),

              onRowDelete: (oldData) =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    let tblData = data;
                    const index = tblData[rowIndex]['days'];
                    const neTtblData = index.filter(
                      (item) =>
                        item !== oldData
                    );
                    tblData[rowIndex]['days'] = neTtblData;
                    dispatch(editCarType(tblData[rowIndex]), "Update");
                  }, 600);
                }),
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
